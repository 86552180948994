html
{
	height: 100%;
	font-family: 'Roboto';
	font-weight: 300;
	overflow-x: hidden;
	overflow-y: hidden;
}

body
{
	height: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: rgba(100, 100, 100, 1.0);
	background-image: url(/static/media/background.5a9013a5.svg);
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

code
{
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#sipcaller
{
	height: 100%;
	width: 100%;
}
.LoadingView
{
	height: 100%;
	width: 100%;
}
